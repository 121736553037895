
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import MyListMixin from '@/mixins/mylist-mixin';
import { Component } from 'vue-property-decorator';

  @Component({
    mixins: [MyListMixin],
    components: {
      BaseToolbar
    }
  })
export default class MyListToolbar extends BaseToolbar {
    editMode!: boolean;
    searchText!: string;
    selectedRows!: Array<any>;
    hasSelectedRows!: boolean;

    get localMyListsComponent(): any {
      return this.currentPageInstance || {};
    }

    get toggleEditMode() {
      return this.localMyListsComponent.toggleEditMode || this.emptyFunction;
    }

    get addNewStandard() {
      return this.localMyListsComponent.addNewStandard || this.emptyFunction;
    }

    get deleteStandards() {
      return this.localMyListsComponent.deleteStandards || this.emptyFunction;
    }
}
